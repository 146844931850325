import React, { useState, useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import SpaceResponsive from 'constants/SpaceResponsive'
import axios from 'axios'
import Cookies from 'universal-cookie'
import mixpanel from 'mixpanel-browser'
import { API_HOST, COOKIE_OPTION, STIPOP_DOMAIN } from 'common/common'
import { WindowSizeContext } from 'pages/_app'
import GlobalBreakPoint from 'constants/GlobalBreakPoint'
import { useEffect } from 'react'

const MobileNav = ({ auth }) => {
  const menuList = [
    {
      logo: 'https://img.stipop.io/image/subscription/my-stickers',
      title: 'My Stickers',
      path: '/my-stickers',
    },
    {
      logo: 'https://img.stipop.io/image/subscription/profile',
      title: 'Profile',
      path: '/profile',
    },
    {
      logo: 'https://img.stipop.io/image/subscription/apps',
      title: 'Apps',
      path: '/apps',
    },
  ]

  const router = useRouter()
  const cookies = new Cookies()
  const size = useContext(WindowSizeContext)
  const [open, setOpen] = useState(false)
  const [scrollMode, setScrollMode] = useState(false)

  const scrollPos = useRef(null)
  const scrollWay = useRef(null)

  useEffect(() => {
    if (!open) {
      checkHeaderScrolled()
      window.addEventListener('scroll', checkHeaderScrolled)
    }
    return () => {
      window.removeEventListener('scroll', checkHeaderScrolled)
    }
  }, [])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (open) {
      body.classList.add('scrollLock')
    } else {
      body.classList.remove('scrollLock')
    }

    if (body.classList.contains('scrollLock')) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }
  }, [open])

  const checkHeaderScrolled = (e) => {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop
    if (scrollY > 30) setScrollMode(true)
    else setScrollMode(false)

    const newScrollWay = scrollY - scrollPos.current > 0 ? 'down' : 'up'
    if (scrollWay.current !== newScrollWay) {
      document.getElementById('header').setAttribute('way', newScrollWay)
    }
    scrollWay.current = newScrollWay
    scrollPos.current = scrollY
  }

  const signOut = async () => {
    await axios
      .post(`${API_HOST}/v4/common/logout`, '', {
        headers: {
          // apikey: process.env.API_KEY,
          token: auth.token,
        },
      })
      .then(async () => {
        mixpanel.track('Sign Out')
        mixpanel.reset()
        await cookies.remove('stipop-token', COOKIE_OPTION)
        await router.push(`${STIPOP_DOMAIN}/signin`)
      })
  }

  return (
    <>
      {size.width < GlobalBreakPoint.web && (
        <Wrapper
          className={open ? 'active' : 'deactive'}
          size={
            size.width >= GlobalBreakPoint.web
              ? 'web'
              : size.width >= GlobalBreakPoint.tablet
              ? 'tablet'
              : 'mobile'
          }
          open={open}
        >
          <SpaceResponsive
            height={auth && auth.user.isSubscribed === 'Y' ? 20 : 53}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {auth && auth.user.isSubscribed === 'Y' && (
              <img
                src="https://img.stipop.io/image/subscription/membership.png"
                alt=""
                style={{ width: '95px', height: '23px', marginBottom: '10px' }}
              />
            )}
            <Logo
              src="https://img.stipop.io/image/b2b/new-logo.png"
              onClick={() => router.push(`/my-stickers`)}
            />
          </div>
          <Menu
            src="https://img.stipop.io/image/account/hamburger_menu.png"
            onClick={() => {
              setOpen(!open)
            }}
            style={{ position: 'absolute', top: '23px', right: '30px' }}
          />
          <SpaceResponsive height={25} />
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#f2f2f2',
              marginLeft: '-15px',
            }}
          />
          <div
            id="menu-items"
            style={{
              width: '100%',
              height: 'calc(100% - 110px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '-15px',
            }}
          >
            <MenuWrapper>
              <SpaceResponsive height={25} />
              {menuList.map((menu, index) => (
                <MenuBox
                  live={router.pathname === menu.path}
                  key={index}
                  onClick={() => {
                    router.push(`${menu.path}`)
                    setOpen(false)
                  }}
                  logo={menu.logo}
                >
                  <img src={`${menu.logo}.png`} />
                  {menu.title}
                </MenuBox>
              ))}
              {auth && auth.user.isSubscribed === 'N' && (
                <MenuBox
                  onClick={() => {
                    mixpanel.track('Button Click', {
                      button_name: 'Account-Membership',
                    })
                    router.push(`${STIPOP_DOMAIN}/plan`)
                  }}
                  logo={
                    'https://img.stipop.io/image/subscription/membership-icon'
                  }
                >
                  <img src="" alt="" />
                  Membership
                </MenuBox>
              )}
            </MenuWrapper>
            <NavBottom>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#f2f2f2',
                }}
              />
              <MenuWrapper>
                <SpaceResponsive height={14.8} />
                <MenuBox
                  onClick={() => {
                    router.push(`${STIPOP_DOMAIN}/en/contact-us`)
                  }}
                  logo={'https://img.stipop.io/image/subscription/support-icon'}
                >
                  <img src="" alt="" />
                  Support
                </MenuBox>
                <MenuBox
                  className="LogoutTrigger"
                  onClick={() => signOut()}
                  logo={'https://img.stipop.io/image/subscription/signout-icon'}
                >
                  <img src="" alt="" />
                  Sign Out
                </MenuBox>
              </MenuWrapper>
            </NavBottom>
          </div>
        </Wrapper>
      )}
      <MobileMenuWrapper $scrollMode={scrollMode} id={'header'} open={open}>
        <Logo
          src="https://img.stipop.io/image/b2b/new-logo.png"
          onClick={() => router.push(`/my-stickers`)}
        />

        <Menu
          src="https://img.stipop.io/image/account/hamburger_menu.png"
          onClick={() => {
            setOpen(!open)
          }}
        />
      </MobileMenuWrapper>
    </>
  )
}

export default MobileNav

const Wrapper = styled.div`
  width: ${(props) => (props.size === 'mobile' ? '100%' : '300px')};
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 26px 0 rgba(202, 202, 202, 0.5);
  padding-left: 30px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: ${(props) => (props.open ? 10 : -1)};
  transition: all 0.4s ease-in-out;

  &.deactive {
    right: ${(props) => (props.size === 'mobile' ? '-100%' : '-300px')};
    opacity: 0;
  }

  &.active {
    right: 0px;
    opacity: 1;
  }

  & #menu-items {
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
`

const Logo = styled.img`
  width: 126px;
  height: 31px;
  cursor: pointer;
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-left: -15px; */
`

const MenuBox = styled.div`
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${(props) => props.live && '#ffe3d8'};
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: ${(props) => (props.live ? '500' : 'normal')};
  color: ${(props) => (props.live ? '#ff4500' : '#000')};
  margin-bottom: 2.5px;
  cursor: pointer;

  img {
    width: 23px;
    height: 23px;
    margin-right: 12.5px;

    content: ${(props) =>
      props.live ? `url(${props.logo}-active.png)` : `url(${props.logo}.png)`};
  }

  &:hover {
    background-color: #ffe3d8;
  }
`

const NavBottom = styled.div`
  width: 100%;
  height: 138.5px;
  /* position: absolute;
  bottom: 0; */
`

const MobileMenuWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 30px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  opacity: 1;
  transition: background-color 0.25s, box-shadow 0.25s,
    transform 0.5s cubic-bezier(0.22, 1, 0.36, 1) opacity 0.4s;
  user-select: none;
  background-color: #fff;
  z-index: 10;

  ${(props) =>
    props.open
      ? css`
          /* display: none; */
          opacity: 0;
          transition: opacity 0.4s;
        `
      : css`
          opacity: 1;
          transition: opacity 0.4s;
        `}

  ${(props) =>
    props.$scrollMode
      ? css`
          box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
        `
      : css`
          background-color: transparent;
          transform: translateY(0) !important;
        `}
  &[way="down"] {
    transform: translateY(-100%);
    box-shadow: none;
  }
`

const Menu = styled.img`
  width: 31px;
  height: 25px;
  z-index: 10;
`
