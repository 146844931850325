const GlobalTheme = {
  color: {
    primary: "#ff4500",
    secondary: "#007aff",
  },
  bp: {
    web: "1299.98px",
    tablet: "767.98px",
    mobile: "359.98px",
  },
};

export default GlobalTheme;
