const GlobalBreakPoint = {
  // content width : 1230px
  // margin left & right : 35px
  web: 1300,
  // content width : 700px
  // margin left & right : 34px
  tablet: 768,
  // content width : -px
  // margin left & right : -px
  mobile: 0,
};

export default GlobalBreakPoint;
