import React, { useContext } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import SpaceResponsive from 'constants/SpaceResponsive'
import axios from 'axios'
import Cookies from 'universal-cookie'
import mixpanel from 'mixpanel-browser'
import { API_HOST, COOKIE_OPTION, STIPOP_DOMAIN } from 'common/common'
import { WindowSizeContext } from 'pages/_app'
import GlobalBreakPoint from 'constants/GlobalBreakPoint'

const SideNav = ({ auth }) => {
  const menuList = [
    {
      logo: 'https://img.stipop.io/image/subscription/my-stickers',
      title: 'My Stickers',
      path: '/my-stickers',
    },
    {
      logo: 'https://img.stipop.io/image/subscription/profile',
      title: 'Profile',
      path: '/profile',
    },
    {
      logo: 'https://img.stipop.io/image/subscription/apps',
      title: 'Apps',
      path: '/apps',
    },
  ]

  const router = useRouter()
  const cookies = new Cookies()
  const size = useContext(WindowSizeContext)

  const signOut = async () => {
    await axios
      .post(`${API_HOST}/v4/common/logout`, '', {
        headers: {
          // apikey: process.env.API_KEY,
          token: auth.token,
        },
      })
      .then(async () => {
        mixpanel.track('Sign Out')
        mixpanel.reset()
        await cookies.remove('stipop-token', COOKIE_OPTION)
        await router.push(`${STIPOP_DOMAIN}/signin`)
      })
  }

  return (
    <Wrapper
      size={
        size.width >= 1024
          ? 'web'
          : size.width >= GlobalBreakPoint.tablet
          ? 'tablet'
          : 'mobile'
      }
    >
      <SpaceResponsive height={59} />
      <div style={{ display: 'flex' }}>
        <Logo
          src="https://img.stipop.io/image/b2b/new-logo.png"
          onClick={() => router.push(`/my-stickers`)}
        />
        {auth && auth.user.isSubscribed === 'Y' && (
          <img
            src="https://img.stipop.io/image/subscription/membership.png"
            alt=""
            style={{ width: '95px', height: '23px', marginLeft: '10px' }}
          />
        )}
      </div>
      <SpaceResponsive height={37} />
      <div
        style={{ width: '203px', height: '1px', backgroundColor: '#f2f2f2' }}
      />
      <SpaceResponsive height={37} />
      <MenuWrapper>
        {menuList.map((menu, index) => (
          <MenuBox
            live={router.pathname === menu.path}
            key={index}
            onClick={() => {
              router.push(`${menu.path}`)
            }}
            logo={menu.logo}
          >
            <img src={`${menu.logo}.png`} />
            {menu.title}
          </MenuBox>
        ))}
        {auth && auth.user.isSubscribed === 'N' && (
          <MenuBox
            onClick={() => {
              mixpanel.track('Button Click', {
                button_name: 'Account-Membership',
              })
              router.push(`${STIPOP_DOMAIN}/plan`)
            }}
            logo={'https://img.stipop.io/image/subscription/membership-icon'}
          >
            <img src="" alt="" />
            Membership
          </MenuBox>
        )}
      </MenuWrapper>
      <NavBottom>
        <MenuWrapper>
          <SpaceResponsive height={14.8} />
          <MenuBox
            onClick={() => {
              router.push(`${STIPOP_DOMAIN}/en/contact-us`)
            }}
            logo={'https://img.stipop.io/image/subscription/support-icon'}
          >
            <img src="" alt="" />
            Support
          </MenuBox>
          <MenuBox
            className="LogoutTrigger"
            onClick={() => signOut()}
            logo={'https://img.stipop.io/image/subscription/signout-icon'}
          >
            <img src="" alt="" />
            Sign Out
          </MenuBox>
        </MenuWrapper>
      </NavBottom>
    </Wrapper>
  )
}

export default SideNav

const Wrapper = styled.div`
  width: ${(props) => (props.size === 'mobile' ? '100%' : '300px')};
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 26px 0 rgba(202, 202, 202, 0.5);
  padding-left: 45px;
  box-sizing: border-box;
  position: fixed;
`

const Logo = styled.img`
  width: 126px;
  height: 31px;
  cursor: pointer;
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: -15px;
`

const MenuBox = styled.div`
  width: 241px;
  height: 43px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: ${(props) => props.live && '#ffe3d8'};
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: ${(props) => (props.live ? '500' : 'normal')};
  color: ${(props) => (props.live ? '#ff4500' : '#000')};
  margin-bottom: 2.5px;
  cursor: pointer;

  img {
    width: 23px;
    height: 23px;
    margin-right: 12.5px;

    content: ${(props) =>
      props.live ? `url(${props.logo}-active.png)` : `url(${props.logo}.png)`};
  }

  &:hover {
    background-color: #ffe3d8;
  }
`

const NavBottom = styled.div`
  width: 203px;
  height: 138.5px;
  border-top: 1px solid #f2f2f2;
  position: absolute;
  bottom: 0;
`
