import React, { Fragment, useEffect, useState, createContext } from 'react'
import GlobalStyles from 'constants/GlobalStyle'
import theme from 'constants/GlobalTheme'
import { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/router'
import Head from 'next/head'
import useWindowSize from 'hooks/useWindowSize'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import useCurrentLanguage from 'hooks/useCurrentLanguage'
import useScroll from 'hooks/useScroll'
import SideNav from 'widgets/SideNav'
import MobileNav from 'widgets/MobileNav'
import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from 'common/common'
import GlobalBreakPoint from 'constants/GlobalBreakPoint'
import axios from 'axios'
import { API_HOST, COOKIE_OPTION, STIPOP_DOMAIN } from 'common/common'
import Cookies from 'universal-cookie'

export const WindowSizeContext = createContext({ width: 0, height: 0 })
export const UserLanguageContext = createContext({ lang: 'en' })
export const ScrollContext = createContext({ scrollMove: false })

const MyApp = ({ Component, pageProps, apikey, auth }) => {
  mixpanel.init(MIXPANEL_TOKEN)
  // mixpanel.init(MIXPANEL_TOKEN, { debug: true })

  const [isLoaded, setIsLoaded] = useState(false)

  const router = useRouter()
  const size = useWindowSize()
  const language = useCurrentLanguage()
  const scrolls = useScroll()

  // meta
  const m_title = pageProps?.meta?.title
    ? pageProps.meta.title
    : 'Stipop Account | Stickers API and SDK | Sticker platform for creators'
  const m_description = pageProps?.meta?.description
    ? pageProps.meta.description
    : '150,000 stickers for messages, videos, and profile icons ready to integrate into any app, created by talented artists from all over the world.'
  const m_image = pageProps?.meta?.image
    ? pageProps.meta.image
    : '/metaImages/thumbnail.png'
  const m_url = pageProps?.meta?.url
    ? pageProps.meta.url
    : 'https://account.stipop.com'

  axios.defaults.headers.common['apikey'] = apikey

  useEffect(() => {
    if (auth) {
      mixpanel.identify(auth.user.userId)
    } else {
      router.push(`${STIPOP_DOMAIN}/signin`)
    }

    return () => {}
  }, [auth])

  i18n.use(initReactI18next).init({
    resources: {
      en: require('../lang/en.json'),
      ko: require('../lang/ko.json'),
    },
    lng: language || 'en',
    fallbackLng: language || 'en',
    interpolation: {
      escapeValue: false,
    },
  })

  useEffect(() => {
    if (scrolls) {
      document.body.classList.remove('scrollbar-display-none')
    } else {
      document.body.classList.add('scrollbar-display-none')
    }
  }, [scrolls])

  useEffect(() => {
    if (size.width) {
      setIsLoaded(true)

      document.oncontextmenu = () => {
        return false
      }

      document.onselectstart = () => {
        return false
      }

      document.ondragstart = () => {
        return false
      }

      document.onselect = () => {
        return false
      }
    }
  }, [size.width])

  return (
    <>
      <Head>
        {/* og meta section */}
        <title>{m_title}</title>
        <meta name="description" content={m_description}></meta>
        <meta property="og:title" content={m_title} />
        <meta property="og:description" content={m_description} />
        <meta name="imgae" property="og:image" content={m_image} />
        {m_image !== '/metaImages/thumbnail.png' && (
          <>
            <meta property="og:image:width" content={'1200px'} />
            <meta property="og:image:height" content={'630px'} />
          </>
        )}
        <meta property="og:url" content={m_url} />
        <meta property="og:site_name" content="Account.stipop.com" />
        <meta property="og:type" content="website" />

        {/* twitter meta section */}
        <meta name="twitter:title" content={m_title} />
        <meta name="twitter:description" content={m_description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="Account.stipop.com" />
        <meta name="twitter:image" content={m_image} />
      </Head>
      <ThemeProvider theme={theme}>
        <WindowSizeContext.Provider value={size}>
          {/* <UserLanguageContext.Provider value={[userLang, setUserLang]}> */}
          <UserLanguageContext.Provider value={language}>
            {isLoaded && (
              <Fragment>
                <GlobalStyles />
                {size.width >= GlobalBreakPoint.web ? (
                  <SideNav auth={auth} />
                ) : (
                  <MobileNav auth={auth} />
                )}
                <div
                  style={{
                    marginLeft: size.width >= GlobalBreakPoint.web && '300px',
                    marginTop: size.width < GlobalBreakPoint.web && '70px',
                  }}
                >
                  <Component {...pageProps} />
                </div>
              </Fragment>
            )}
          </UserLanguageContext.Provider>
        </WindowSizeContext.Provider>
      </ThemeProvider>
    </>
  )
}

MyApp.getInitialProps = async ({ ctx, Component }) => {
  let pageProps = {}
  let apikey = process.env.API_KEY
  let auth = null
  let cookies

  if (ctx.req) {
    cookies = new Cookies(ctx.req.headers.cookie || ctx.req.cookies)
  } else {
    cookies = new Cookies()
  }

  const token = cookies.get('stipop-token')

  if (token) {
    try {
      axios.defaults.headers.common['apikey'] = apikey
      const response = await axios.get(`${API_HOST}/v4/mypage`, {
        headers: {
          token: token,
        },
      })

      auth = {
        token,
        user: response.data.body.userInfo,
      }
    } catch (error) {}
  }

  ctx.auth = auth

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return {
    pageProps,
    apikey,
    auth,
  }
}

export default MyApp
