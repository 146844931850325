import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

const useCurrentLanguage = () => {
  const router = useRouter();
  const [lang, setLang] = useState('en');

  useEffect(()=>{
    function handlePath() {
      let pathArray = router.pathname.split('/');
      pathArray.shift();

      if(pathArray[0]==='en' || pathArray[0]==='ko'){
        // path에 en || ko가 있을 때는 해당 언어 페이지로

        // // 쿠키 설정
        // const cookies = new Cookies();
        // const exist = cookies.get('user-lang');
        // if(exist){
        //   // 기존 쿠키 지우고 새로 set
        //   console.log(exist)
        //   cookies.remove('user-lang');
        // }
        // cookies.set('user-lang', pathArray[0]); // 이게 문젠디 겹쳐 키가 같은데 왜 안지워짐

        setLang(pathArray[0]);
      }
      else {
        // 쿠키로 언어 설정 
        // (brands 페이지, brands contact-us로 이동시 자동 언어 설정)
        let tmpLang = 'en';
        let cookies;

        tmpLang = navigator.language && navigator.language.split('-').shift();
        cookies = new Cookies();
                
        const cookieLang = cookies.get('user-lang');
        if (cookieLang) {
          tmpLang = cookieLang;
        }
        else {
          if(tmpLang!=='en' && tmpLang!=='ko')
            tmpLang = 'en';
          cookies.set('user-lang', tmpLang, {path: '/brands/contact'});
        }
        setLang(tmpLang);
      }
    }

    window.addEventListener('load', handlePath);
    handlePath();
    return () => window.removeEventListener('load', handlePath);
  }, [router.pathname])

  return [lang, setLang];
};

export default useCurrentLanguage;