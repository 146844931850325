import { useState, useEffect, useRef } from "react";

const useWindowSize = () => {
  const [scrollMove, setScrollMove] = useState(false);
  
  useEffect(()=>{
    let handle = null;
    function onScroll(){
      if(handle){ // scroll start
        clearTimeout(handle);
        setScrollMove(true);
      }
      handle = setTimeout(()=>{ // scroll stop
        setScrollMove(false);
      }, 400) // 600ms 동안 스크롤 없으면 === scroll stop으로 간주
    }
    
    window.addEventListener('scroll', onScroll);
    return ()=>{
      window.removeEventListener('scroll', onScroll);
    }
  },[]);

  return scrollMove;
};

export default useWindowSize;