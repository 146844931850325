import { createGlobalStyle } from 'styled-components'
import theme from './GlobalTheme'

const GlobalStyle = createGlobalStyle`
  /* @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-display: swap; 
    src:
      url(https://fonts.gstatic.com/s/manrope/v12/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
    unicode-range: 
      U+0041-005A, U+0061-007A, // 영어 대문자, 소문자
      U+AC00-D7A3, // 한글
      U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, // 특수문자
      U+0030-0039; // 숫자
  }
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/manrope/v12/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FN_P-b_Tfc7AGraJwA.woff) format('woff');
    unicode-range: 
      U+0041-005A, U+0061-007A, // 영어 대문자, 소문자
      U+AC00-D7A3, // 한글
      U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, // 특수문자
      U+0030-0039; // 숫자
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtfCRc4AMP6lbBP.woff2) format('woff2');
    unicode-range:  
      U+0041-005A, U+0061-007A, // 영어 대문자, 소문자
      U+AC00-D7A3, // 한글
      U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, // 특수문자
      U+0030-0039; // 숫자
  } */

  body {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    line-height: 1.44;
    margin: 0px;
    color: #2b292d;
    overflow-x: hidden;
    overflow-y: auto;
    width:100vw;
    word-break: keep-all;
    user-select: none;
    -webkit-touch-callout: none;

    @media (max-width: ${(props) => props.theme.bp.web}) {
      /* background-color: silver; */
    } 
    
    @media (max-width: ${(props) => props.theme.bp.tablet}) {
      /* background-color: purple; */
    }

    @media (max-width: ${(props) => props.theme.bp.mobile}) {
      /* background-color: rosybrown; */
    }
  }

  body > div#sendbird-modal-root > .sendbird-fileviewer {
    display: none !important;
  }

  
  a {
    text-decoration: none;
    color: inherit;
    width: fit-content;
    height: fit-content;
  }

  input {
    font-family: Manrope;
  }
  
  input:focus {
    outline: none;
  }

  textarea {
    font-family: Manrope;
    resize: none;
  }

  textarea:focus {
    outline: none;
  }

  .page_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    overflow-x: hidden;
  }

  .section_background {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .section_container {
    padding: 80px 35px 80px 35px;
    width: 100%;
    max-width: 1230px;
    min-width: 1230px;
    @media (max-width: ${(props) => props.theme.bp.web}) {
      padding: 40px 34px 40px 34px;
      max-width: 700px;
      min-width: 700px;
    }
    @media (max-width: ${(props) => props.theme.bp.tablet}) {
      padding: 40px 15px 40px 15px;
      width: calc(100% - 30px);
      max-width: 470px;
      min-width: 330px;
    }
  }

  .body_block_scrolling {
    overflow-y: hidden;
  }
  
  .material-icons {
    user-select: none;
  }

  .scrollbar-display-none ::-webkit-scrollbar-thumb {
    visibility: hidden;

    :hover{
      visibility: visible;
    }
  }

  /* scrollbar Start */
  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.color.primary};
    transition: all 0.25s;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    /* background-color: ${theme.color.primary}; */
    background-color: rgba(80, 80, 80, 0.6);
    transition: all 0.25s;
  }
  ::-webkit-scrollbar-thumb:vertical:hover {
    background-color: ${theme.color.primary};
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.color.primary};
  }
  /* scrollbar End */
`

export default GlobalStyle
