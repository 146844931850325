export const API_HOST = 'https://api.stipop.com'
export const API_KEY = '0b584babc5d2c068d08b8e4f120f1183' // for chrome
export const MIXPANEL_TOKEN = 'b054d442cd17eb3cbc562f987075e8c4'
export const STIPOP_DOMAIN = 'https://stipop.com'
export const COOKIE_OPTION = {
  path: '/',
  domain: 'stipop.com',
}

// for test server
// export const API_HOST = 'http://localhost:3000'
// // export const API_HOST = 'https://sandbox.stipop.com'
// export const API_KEY = 'a3e68b6c54d8132f6879d5bc2c49708d'
// export const MIXPANEL_TOKEN = 'c3adc6c049e05186a3fd08392014db4e'
// export const STIPOP_DOMAIN = 'http://localhost:3002'
// export const COOKIE_OPTION = {
//   path: '/',
//   sameSite: 'strict',
// }
